body {
    background-color: #f0f4f8;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

h1, h2 {
    color: #2c3e50;
    text-align: center;
}

form {
    background: #ffffff;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

form div {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="number"],
input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-left: -10px ;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

button[type="submit"] {
    background: #2c3e50;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
    width: 100%;
}

button[type="submit"]:hover {
    background: #34495e;
}

.results {
    background: #ffffff;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.health-tips {
    background: #ecf8f8;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    color: #2c3e50;
}

.health-tips ul {
    list-style-type: disc;
    padding-left: 20px;
}

.health-tips li {
    margin-bottom: 10px;
}

.health-tips li::marker {
    color: #16a085;
}

.health-tips h2 {
    text-align: center;
    color: #16a085;
}

.health-tips ul {
    list-style-type: square;
}

.health-tips li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

.health-tips li::marker {
    color: #16a085;
}

.health-tips h2 {
    text-align: center;
    color: #16a085;
}

.health-tips ul {
    list-style-type: square;
}

.health-tips li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

@media (max-width: 768px) {
    form, .results, .health-tips {
        padding: 15px;
        margin: 15px;
    }

    input[type="text"],
    input[type="number"],
    input[type="date"] {
        font-size: 0.9em;
    }

    button[type="submit"] {
        font-size: 0.9em;
    }
}

/* Daha hoş bir genel görünüm ve okunabilirlik için basit ve minimal bir stil */

.health-tips {
    background: #ecf8f8;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    color: #2c3e50;
}

.health-tips ul {
    list-style-type: disc;
    padding-left: 20px;
}

.health-tips li {
    margin-bottom: 10px;
}

.health-tips h2 {
    text-align: center;
    color: #16a085;
}

.health-tips ul {
    list-style-type: square;
}

.health-tips li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

.health-tips li::marker {
    color: #16a085;
}

.health-tips ul {
    list-style-type: square;
    padding-left: 20px;
}

.health-tips li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    form, .results, .health-tips {
        padding: 15px;
        margin: 15px;
    }

    input[type="text"],
    input[type="number"],
    input[type="date"] {
        font-size: 0.9em;
    }

    button[type="submit"] {
        font-size: 0.9em;
    }
}