.health-tips-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    gap: 20px;
}

.main-content {
    flex: 3;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.sidebar {
    flex: 2;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.main-video-container, .sidebar-video-container {
    margin-bottom: 20px;
}

h2, h3 {
    color: #2c3e50;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    margin-bottom: 15px;
    line-height: 1.6;
}

iframe {
    border: none;
}

@media (max-width: 768px) {
    .health-tips-container {
        flex-direction: column;
    }

    .main-content, .sidebar {
        width: 100%;
        margin-bottom: 20px;
    }
}